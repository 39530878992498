import React, { useState } from "react";
import {
  InputDateRange,
  InputSelect,
  SearchBox,
  Button,
} from "@bluesilodev/timhutcomponents";
import { FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import AttendanceApprovalSVG from "assets/icon/AttendanceApprovalSVG/AttendanceApprovalSVG";
import UserAdd from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import { AttendanceApprovalRequestAttendanceModalForm } from "pages/AttendanceRequest/forms";
import { AttendanceApprovalDataTable } from "pages/AttendanceRequest/tables";
import { useAlertApi } from "services/alert-api";
import axios from "services/axios";
import { useFetchAttendanceQuery } from "store/apis/attendanceDataApi";
import { useFetchAttendanceRequestQuery, useAddAttendanceRequestMutation, useDeleteAttendanceRequestMutation } from "store/apis/attendanceRequestApi";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";
import { convertDateTimeToArray } from "utils/common";

// import { useAttendanceRequest } from "store/slices/attendanceRequestSlice";
// import { useSelector } from "react-redux";
// import { useAttendanceData } from "store/slices/attendanceDataSlice";

const AttendanceApproval = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Formik
  const formik = useFormik({
    initialValues: {
      attID: "",
      scheduleID: [],
      uId: "3daf2237-28d2-49e9-bb04-7e665c77763b",
      punchInDate: "",
      punchInTime: "00:00",
      punchOutDate: "",
      punchOutTime: "00:00",
      notes: "",
    },
    validationSchema: Yup.object().shape({
      attID: Yup.string(),
      uId: Yup.string(),
      scheduleID: Yup.string().required('Schedule ID is required'),
      punchInDate: Yup.string().required('Punch In Date is required'),
      punchInTime: Yup.string().required('Punch In Time is required'),
      punchOutDate: Yup.string().required('Punch Out Date is required'),
      punchOutTime: Yup.string().required('Punch Out Time is required'),
      notes: Yup.string().required('Notes is required'),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      const separatePunchInTime = values.punchInTime.split(":");
      const separatePunchInDate = values.punchInDate.split("/");
      const separatePunchOutTime = values.punchOutTime.split(":");
      const separatePunchOutDate = values.punchOutDate.split("/");
      const punchInDate = new Date(separatePunchInDate[2], separatePunchInDate[1] - 1, separatePunchInDate[0], separatePunchInTime[0], separatePunchInTime[1], 0);
      const punchOutDate = new Date(separatePunchOutDate[2], separatePunchOutDate[1] - 1, separatePunchOutDate[0], separatePunchOutTime[0], separatePunchOutTime[1], 0);
      requestAttendanceRequest({
        uId: values.uId,
        shift: values.scheduleID,
        punchIn: punchInDate,
        punchOut: punchOutDate,
        notes: values.notes,
      });
      handleCloseModal();
    },
  });
  // Data State
  const [roles, setRoles] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [filledFields, setFilledFields] = useState({ scheduleID: false, punchIn: false, punchOut: false });
  const [paginate, setPaginate] = useState({ totalData: 0, rowsPerPage: 10, currentPage: 1 });
  const [filterData, setFilterData] = useState({ search: "", searchDateEnable: 0, startDate: new Date(), endDate: new Date(), location: "", department: "", approval: "" });
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const { data: dataLocationDep, error: errorLocationDep, isFetching: isFetchingLocationDep } = useFetchLocationDepartmentQuery(null, { refetchOnMountOrArgChange: true });
  const { data: data, error: errorAttendanceRequest, isFetching: isFetchingAttendanceRequest } = useFetchAttendanceRequestQuery({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage }, { refetchOnMountOrArgChange: true });
  const { data: attendanceData, error: errorAttendance, isFetching: isFetchingAttendance } = useFetchAttendanceQuery({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage }, { refetchOnMountOrArgChange: true });
  const [requestAttendanceRequest, requestAttendanceRequestResult] = useAddAttendanceRequestMutation();
  const [deleteAttendanceRequest, deleteAttendanceRequestResults] = useDeleteAttendanceRequestMutation();
  // Static State
  const dataTimeRange = [
    "Today",
    "Past Week",
    "Last Month",
    "This Month",
    "Custom Date",
  ];
  const dataLocation = ["Cafe Halim", "Kedai Kebab", "Kedai Kopi"];
  const dataDepartment = ["All Department", "Cafe Halim", "Kedai Kebab"];
  const dataApproval = ["All Approval", "Approved", "Rejected"];
  let dataLength;
  if (data && data.data) {
    dataLength = data.data.length;
  } else {
    dataLength = 0;
  }
  const approval = [{ label: "Approved", value: "approved" }, { label: "Pending", value: "pending" }, { label: "Rejected", value: "rejected" }];

  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 1);
  React.useEffect(() => {
    setFilterData((oldV) => ({ ...oldV, searchDateEnable: oldV.searchDateEnable + 1, startDate: firstDay, endDate: lastDay }))
  }, []);

  React.useEffect(() => {
    const departmentTempData = globalFilter.locations.filter((item) => item.locationName === filterData.location || filterData.location === "").flatMap((entry) => entry.departments).map((item, index) => {
      return {
        label: item.department,
        value: item.department,
      };
    });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    })
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (department.find((value) => value.value === departmentVal.department)) return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    if (data) {
      const switchCaseApproval = (status) => {
        switch (status) {
          case "approved": return "Approved";
          case "rejected": return "Rejected";
          case "pending": return "Pending";
          default: return "Pending";
        }
      };
      setPaginate((oldData) => ({ ...oldData, totalData: data.totalDoc }));
      const attendanceRequest = data.data.map((val) => {
        return {
          ...val,
          approvalStatus: {
            bySupervisor: {
              status: switchCaseApproval(val.approvalStatus.bySupervisor.status),
            },
            byHr: {
              status: switchCaseApproval(val.approvalStatus.byHr.status),
            },
          },
        };
      });
      setDataTable(attendanceRequest);
    }
  }, [data]);

  useAlertApi(requestAttendanceRequestResult);
  useAlertApi(deleteAttendanceRequestResults);

  // const userData = useSelector((state) => state.auth.user);
  // const [{ data }] = useState({ data: { data: [
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "25 August 2023",
  //     shift: "Morning Shift",
  //     punch_in: {
  //       time: "08:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "17:00",
  //       date: "25 August 2023",
  //     },
  //     total_hour: "10 Hours",
  //     ot_duration: "-",
  //     status: "On Time",
  //     approval: "Approved",
  //     employeeInfo: {
  //       firstName: "",
  //     },
  //     approvalStatus: {
  //       isHr: { status: "Approved" },
  //       isManager: { status: "Approved" },
  //     }
  //   },
  //   /*
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "27 September 2023",
  //     shift: "Night Shift",
  //     punch_in: {
  //       time: "20:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "05:00",
  //       date: "26 August 2023",
  //     },
  //     total_hour: "9 Hours",
  //     ot_duration: "-",
  //     status: "Late",
  //     approval: "Rejected",
  //   },
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "27 September 2023",
  //     shift: "Night Shift",
  //     punch_in: {
  //       time: "20:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "05:00",
  //       date: "26 August 2023",
  //     },
  //     total_hour: "9 Hours",
  //     ot_duration: "-",
  //     status: "Late",
  //     approval: "Pending",
  //   },
  //   */
  // ]}})
  // const [{ data: attendanceData }] = useState({ data: { data: [
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "25 August 2023",
  //     shift: "Morning Shift",
  //     punch_in: {
  //       time: "08:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "17:00",
  //       date: "25 August 2023",
  //     },
  //     total_hour: "10 Hours",
  //     ot_duration: "-",
  //     status: "On Time",
  //     approval: "Approved",
  //     employeeInfo: {
  //       firstName: "",
  //     },
  //     approvalStatus: {
  //       isHr: { status: "Approved" },
  //       isManager: { status: "Approved" },
  //     }
  //   },
  //   /*
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "27 September 2023",
  //     shift: "Night Shift",
  //     punch_in: {
  //       time: "20:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "05:00",
  //       date: "26 August 2023",
  //     },
  //     total_hour: "9 Hours",
  //     ot_duration: "-",
  //     status: "Late",
  //     approval: "Rejected",
  //   },
  //   {
  //     employee_name: "John Doe",
  //     job_position: "IT",
  //     request_date: "27 September 2023",
  //     shift: "Night Shift",
  //     punch_in: {
  //       time: "20:00",
  //       date: "25 August 2023",
  //     },
  //     punch_out: {
  //       time: "05:00",
  //       date: "26 August 2023",
  //     },
  //     total_hour: "9 Hours",
  //     ot_duration: "-",
  //     status: "Late",
  //     approval: "Pending",
  //   },
  //   */
  // ]}})

  // useEffect(() => {
  //   const { scheduleID, punchIn, punchOut } = filledFields;
  //   const hasAttendanceData =
  //     attendanceData && attendanceData.data && attendanceData.data.length > 0;

  //   if (scheduleID && punchIn && punchOut && hasAttendanceData) {
  //     const firstAttendanceData = attendanceData.data[0];
  //     if (firstAttendanceData.punchIn && firstAttendanceData.punchOut) {
  //       setPunchInTime(convertDateTimeToArray(firstAttendanceData.punchIn));
  //       setPunchOutTime(convertDateTimeToArray(firstAttendanceData.punchOut));
  //       setAttID(firstAttendanceData.uId);
  //     } else {
  //       setPunchInTime([0, 0]);
  //       setPunchOutTime([0, 0]);
  //       setAttID(null);
  //     }
  //   } else {
  //     setPunchInTime([0, 0]);
  //     setPunchOutTime([0, 0]);
  //     setAttID(null);
  //   }
  // }, [filledFields, attendanceData]);

  // useEffect(() => {
  //   if (attendanceData && attendanceData.data) {
  //     setLocations(extractUniqueValues(attendanceData.data, "location"));
  //     setDepartments(extractUniqueValues(attendanceData.data, "department"));
  //     setRoles(
  //       extractUniqueValues(attendanceData.data, "employeeInfo.jobLevel")
  //     );
  //   }
  // }, [attendanceData]);

  // useEffect(() => {
  //   if (attendanceData && attendanceData.data && !scheduleIDs.length) {
  //     setScheduleIDs(extractUniqueValues(attendanceData.data, "scheduleID"));
  //   }
  // }, [attendanceData, scheduleIDs]);

  const extractUniqueValues = (data, key) => [
    ...new Set(
      data.map((obj) => key.split(".").reduce((acc, prop) => acc[prop], obj))
    ),
  ];

  const attendanceHandleChange = (field, value) => {
    // setQueryParamsData((prevParams) => ({
    //   ...prevParams,
    //   [field]: value,
    // }));

    setFilledFields((prevFields) => ({
      ...prevFields,
      [field]: Boolean(value),
    }));
  };

  const clickExportAttendanceRequest = () => {
    axios
      .get(`/api/attendance-request/?export=true&${Object.entries({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage } || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`)
      .then((response) => {
        const href = URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8," }));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "download.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const onDeleteClickDataTable = (data) => {
    if (window.confirm("Are You Sure Want To Delete?")) {
      deleteAttendanceRequest({
        attendanceRequestID: data.uId,
      });
    }
  };

  return (
    <>
      {location.pathname === "/attendance-approval" && (
        <div className="main w-full relative mt-5 p-1">
          <FormikProvider value={formik}>
            <AttendanceApprovalRequestAttendanceModalForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          </FormikProvider>
          <div className="flex items-center">
            <div className="input-select w-[350px] h-[58px]">
              <InputDateRange
                label={"Time Range"}
                value={[firstDay, lastDay]}
                setFieldValue={(_, resultArrayDate) => {
                  setFilterData((oldV) => ({
                    ...oldV,
                    searchDateEnable: oldV.searchDateEnable + 1,
                    startDate: resultArrayDate[0],
                    endDate: resultArrayDate[resultArrayDate.length - 1],
                  }));
                }}
                error={""}
              />
            </div>
            <div className="pl-4 input-select w-[200px] h-[58px]">
              <InputSelect
                name={"select_location"}
                title={"Locations"}
                options={locations}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, location: e.target.value }))}
              />
            </div>
            <div className="pl-4 input-select w-[200px] h-[58px]">
              <InputSelect
                name={"select_department"}
                title={"Departments"}
                options={departments}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, department: e.target.value }))}
              />
            </div>
            <div className="pl-4 input-select w-[200px] h-[58px]">
              <InputSelect
                name={"select_approval"}
                title={"Approval"}
                options={approval}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, approval: e.target.value }))}
              />
            </div>
            <div className="ml-auto flex pl-4">
              <div>
                <SearchBox
                  onChange={(e) => onChangeTypeSearch(e)}
                  className={"h-[58px] w-[250px]"}
                  placeholder={"Search"}
                />
              </div>
              <div className=" ml-4">
                {currentRole === "Admin" ? (
                  <Button
                    className={"w-[150px]"}
                    onClick={clickExportAttendanceRequest}
                    label={
                      <div className="flex px-4 gap-1">
                        <UserAdd color={"#DD7224"} />
                        <div>Export</div>
                      </div>
                    }
                  />
                ) : (
                  <Button
                    label={
                      <div className="flex items-center px-4 gap-1">
                        <AttendanceApprovalSVG color={"white"} />
                        <div>Request Attendance</div>
                      </div>
                    }
                    style={"solid"}
                    className={"w-[220px] h-[2px]"}
                    onClick={handleOpenModal}
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <FetchingAndError isFetching={isFetchingAttendanceRequest && isFetchingAttendance} isError={errorAttendanceRequest && errorAttendance}>
              <AttendanceApprovalDataTable
                dataTable={dataTable}
                currentPage={paginate.currentPage}
                totalData={paginate.totalData}
                rowsPerPage={paginate.rowsPerPage}
                onChangeCurrentPage={(val) => setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))}
                onChangeRowsPerPage={(val) => setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))}
                onClickRedirect={(path) => navigate(path)}
                onDeleteClick={onDeleteClickDataTable}
              />
            </FetchingAndError>
          </div>
        </div>
      )}

      <Outlet />
    </>
  );
};

export default AttendanceApproval;
