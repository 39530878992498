import React, { useState } from "react";
import {
  InputDateRange,
  InputSelect,
  SearchBox,
  Button,
} from "@bluesilodev/timhutcomponents";
import { FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import AttendanceOvertimeSVG from "assets/icon/AttendanceOvertimeSVG/AttendanceOvertimeSVG";
import UserAdd from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import { AttendanceOvertimeRequestOvertimeModalForm } from "pages/AttendanceOvertime/forms";
import { AttendanceOvertimeDataTable } from "pages/AttendanceOvertime/tables";
import { useAlertApi } from "services/alert-api";
import axios from "services/axios";
import { useFetchAttendanceByUserIDQuery, useFetchSingleAttendanceByDateUserIDQuery } from "store/apis/attendanceDataApi";
import { useFetchAttendanceOvertimeQuery, useAddAttendanceOvertimeMutation, useDeleteAttendanceOvertimeMutation } from "store/apis/attendanceOvertimeApi";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";

const AttendanceOvertime = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Formik
  const formik = useFormik({
    initialValues: {
      attendanceData: [],
      attendanceOption: [],
      attendanceID: "",
      attendanceDate: new Date(),
      attendancePunchInTime: "00:00",
      attendancePunchOutTime: "00:00",
      overtimeDuration: "00:00",
      overtimeDate: new Date(),
      notes: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      attendanceID: Yup.string().required('Attendance record not found'),
      attendancePunchInTime: Yup.string().required('Attendance Punch In Time is required'),
      attendancePunchOutTime: Yup.string().required('Attendance Punch Out Time is required'),
      overtimeDuration: Yup.string().required('Overtime Duration is required'),
      notes: Yup.string().required('Notes is required'),
    }),
    onSubmit: (values) => {
      requestAttendanceOvertime(values);
      handleCloseModal();
    },
  });
  // Data State
  const [dataTable, setDataTable] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [paginate, setPaginate] = useState({ totalData: 0, rowsPerPage: 10, currentPage: 1 });
  const [filterData, setFilterData] = useState({ search: "", searchDateEnable: 0, startDate: new Date(), endDate: new Date(), location: "", department: "", approval: "" });
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const { data: dataLocationDep, error: errorLocationDep, isFetching: isFetchingLocationDep } = useFetchLocationDepartmentQuery(null, { refetchOnMountOrArgChange: true });
  const { data: dataAttendanceOvertime, error: errorAttendanceOvertime, isFetching: isFetchingAttendanceOvertime } = useFetchAttendanceOvertimeQuery({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage }, { refetchOnMountOrArgChange: true });
  // // // const { data: dataAttendanceSingle, error: errorAttendanceSingle, isFetching: isFetchingAttendanceSingle } = useFetchSingleAttendanceByDateUserIDQuery({ date: formik.values.overtimeDate }, { refetchOnMountOrArgChange: true });
  const { data: dataAttendance, error: errorAttendance, isFetching: isFetchingAttendance } = useFetchAttendanceByUserIDQuery({ refetchOnMountOrArgChange: true });
  const [requestAttendanceOvertime, requestAttendanceOvertimeResult] = useAddAttendanceOvertimeMutation();
  const [deleteAttendanceOvertime, deleteAttendanceOvertimeResults] = useDeleteAttendanceOvertimeMutation();
  // Static State
  const dataTimeRange = [
    "Today",
    "Past Week",
    "Last Month",
    "This Month",
    "Custom Date",
  ];
  const dataEmployee = ["John Doe", "Harley"];
  const dataLocation = ["Cafe Halim", "Kedai Kebab", "Kedai Kopi"];
  const dataDepartment = ["All Department", "Cafe Halim", "Kedai Kebab"];
  const dataApproval = ["All Approval", "Approved", "Rejected"];
  const approval = [{ label: "Approved", value: "approved" }, { label: "Pending", value: "pending" }, { label: "Rejected", value: "rejected" }];

  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 1);
  React.useEffect(() => {
    setFilterData((oldV) => ({ ...oldV, searchDateEnable: oldV.searchDateEnable + 1, startDate: firstDay, endDate: lastDay }))
  }, []);

  React.useEffect(() => {
    const departmentTempData = globalFilter.locations.filter((item) => item.locationName === filterData.location || filterData.location === "").flatMap((entry) => entry.departments).map((item, index) => {
      return {
        label: item.department,
        value: item.department,
      };
    });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    })
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (department.find((value) => value.value === departmentVal.department)) return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    if (formik.values.scheduleID) {
      const data = formik.values.attendanceData.filter((val) => val.scheduleID === formik.values.scheduleID).map((val) => {
        return {
          attendanceID: val.uId,
          shift: val.scheduleID,
          punchIn: val.punchIn,
          punchOut: val.punchOut,
          overtimeDate: val.punchIn,
          value: val.uId,
          label: new Date(val.punchIn).toLocaleDateString(),
        }
      });
      formik.setFieldValue("attendanceOption", data || []);
    } else {
      formik.setFieldValue("attendanceOption", []);
    }
  }, [formik.values.scheduleID]);

  React.useEffect(() => {
    if (dataAttendance) {
      formik.setFieldValue("attendanceData", dataAttendance.data || []);
    }
  }, [dataAttendance])

  /*
  React.useEffect(() => {
    if (errorAttendanceSingle) {
      formik.setFieldValue("attendanceID", "");
      formik.setFieldError("overtimeDate", errorAttendanceSingle.data.message)
    }
  }, [errorAttendanceSingle]);

  React.useEffect(() => {
    axios
      .get(`/api/attendance/get-by-date?${Object.entries({ date: formik.values.overtimeDate }).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`)
      .then((response) => {
        const dataAttendanceSingle = response.data;
        if (dataAttendanceSingle) {
          const punchIn = new Date(dataAttendanceSingle.data.punchIn);
          const punchOut = new Date(dataAttendanceSingle.data.punchOut);
          formik.setFieldValue("attendanceID", dataAttendanceSingle.data.uId);
          formik.setFieldValue("attendanceDate", punchIn); // `${setDigit(punchIn.getDate())}/${setDigit(punchIn.getMonth() + 1)}/${punchIn.getFullYear()}`
          formik.setFieldValue("attendancePunchInTime", `${punchIn.getHours()}:${punchIn.getMinutes()}`);
          formik.setFieldValue("attendancePunchOutTime", `${punchOut.getHours()}:${punchOut.getMinutes()}`);
        }
      })
      .catch(() => {
        formik.setFieldValue("attendancePunchInTime", `00:00`);
        formik.setFieldValue("attendancePunchOutTime", `00:00`);
      });
  }, [formik.values.overtimeDate]);

  React.useEffect(() => {
    if (dataAttendanceSingle) {
      const setDigit = (number) => {
        if (number > 9) return number
        else return `0${number}`
      }
      const punchIn = new Date(dataAttendanceSingle.data.punchIn);
      const punchOut = new Date(dataAttendanceSingle.data.punchOut);
      formik.setFieldValue("attendanceID", dataAttendanceSingle.data.uId);
      formik.setFieldValue("attendanceDate", punchIn); // `${setDigit(punchIn.getDate())}/${setDigit(punchIn.getMonth() + 1)}/${punchIn.getFullYear()}`
      formik.setFieldValue("attendancePunchInTime", `${punchIn.getHours()}:${punchIn.getMinutes()}`);
      formik.setFieldValue("attendancePunchOutTime", `${punchOut.getHours()}:${punchOut.getMinutes()}`);
      formik.setFieldValue("overtimeDate", punchIn); // `${setDigit(punchIn.getDate())}/${setDigit(punchIn.getMonth() + 1)}/${punchIn.getFullYear()}`
    }
  }, [dataAttendanceSingle])
  */

  React.useEffect(() => {
    if (dataAttendanceOvertime) {
      const switchCaseApproval = (status) => {
        switch (status) {
          case "approved": return "Approved";
          case "rejected": return "Rejected";
          case "pending": return "Pending";
          default: return "Pending";
        }
      };
      setPaginate((oldData) => ({ ...oldData, totalData: dataAttendanceOvertime.totalDoc }));
      const attendanceOvertime = dataAttendanceOvertime.data.map((val) => {
        return {
          ...val,
          approvalStatus: {
            bySupervisor: {
              status: switchCaseApproval(val.approvalStatus.bySupervisor.status),
            },
            byHr: {
              status: switchCaseApproval(val.approvalStatus.byHr.status),
            },
          },
        };
      });
      setDataTable(attendanceOvertime);
    }
  }, [dataAttendanceOvertime]);

  useAlertApi(requestAttendanceOvertimeResult);
  useAlertApi(deleteAttendanceOvertimeResults);

  const clickExportAttendanceOvertime = () => {
    axios
      .get(`/api/attendance-overtime/?export=true&${Object.entries({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage } || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`)
      .then((response) => {
        const href = URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8," }));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "download.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const onDeleteClickDataTable = (data) => {
    if (window.confirm("Are You Sure Want To Delete?")) {
      deleteAttendanceOvertime({
        attendanceOvertimeID: data.uId,
      });
    }
  };

  return (
    <>
      {location.pathname === "/attendance-overtime" && (
        <div className="main w-full relative mt-5 p-1">
          <FormikProvider value={formik}>
            <AttendanceOvertimeRequestOvertimeModalForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          </FormikProvider>
          <div className="flex items-center">
            <div className="input-select w-[350px]">
              <InputDateRange
                label={"Time Range"}
                value={[firstDay, lastDay]}
                setFieldValue={(_, resultArrayDate) => {
                  setFilterData((oldV) => ({
                    ...oldV,
                    searchDateEnable: oldV.searchDateEnable + 1,
                    startDate: resultArrayDate[0],
                    endDate: resultArrayDate[resultArrayDate.length - 1],
                  }));
                }}
                error={""}
              />
            </div>
            <div className="pl-4 input-select w-[200px]">
              <InputSelect
                name={"select_location"}
                title={"Locations"}
                options={locations}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, location: e.target.value }))}
              />
            </div>
            <div className="pl-4 input-select w-[200px]">
              <InputSelect
                name={"select_department"}
                title={"Departments"}
                options={departments}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, department: e.target.value }))}
              />
            </div>
            <div className="pl-4 input-select w-[200px]">
              <InputSelect
                name={"select_approval"}
                title={"Approval"}
                options={approval}
                required={false}
                classname={"h-[58px]"}
                onChange={(e) => setFilterData((oldV) => ({ ...oldV, approval: e.target.value }))}
              />
            </div>
            <div className="ml-auto flex pl-4">
              <div>
                <SearchBox
                  onChange={(e) => onChangeTypeSearch(e)}
                  className={"h-[58px] w-[250px]"}
                  placeholder={"Search"}
                />
              </div>
              <div className="my-auto ml-4">
                {currentRole === "Admin" ? (
                  <Button
                    className={"w-[150px]"}
                    onClick={clickExportAttendanceOvertime}
                    label={
                      <div className="flex px-4 gap-1">
                        <UserAdd color={"#DD7224"} />
                        <div>Export</div>
                      </div>
                    }
                  />
                ) : (
                  <Button
                    label={
                      <div className="flex items-center px-4 gap-1">
                        <AttendanceOvertimeSVG color={"white"} />
                        <div>Request Overtime</div>
                      </div>
                    }
                    style={"solid"}
                    width={"200"}
                    onClick={handleOpenModal}
                    className={"w-[220px] h-[2px]"}
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <FetchingAndError isFetching={isFetchingAttendanceOvertime} isError={errorAttendanceOvertime}>
              <AttendanceOvertimeDataTable
                dataTable={dataTable}
                currentPage={paginate.currentPage}
                totalData={paginate.totalData}
                rowsPerPage={paginate.rowsPerPage}
                onChangeCurrentPage={(val) => setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))}
                onChangeRowsPerPage={(val) => setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))}
                onClickRedirect={(path) => navigate(path)}
                onDeleteClick={onDeleteClickDataTable}
              />
            </FetchingAndError>
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default AttendanceOvertime;
